/* You can add global styles to this file, and also import other style files */
@import "node_modules/ng-zorro-antd/ng-zorro-antd.css";
.up-arrow {
  color: #13c181;
}

.down-arrow {
  color: red;
}
abp-breadcrumb {
  display: none;
}

.modal.task-details {
  --bs-modal-border-radius: 1rem !important;
}

.cke_notifications_area {
  display: none;
}

.is-invalid .invalid-feedback, .is-invalid + * .invalid-feedback {
  display: none !important;
}

h1.content-header-title {
  display: none;
}

body {
  &.ant-dark {

    .ck.ck-editor__main > .ck-editor__editable {
      background: black;
    }

    .ngx-datatable.material {
      background: inherit !important;
    }

    .ngx-datatable.material .datatable-header .datatable-header-cell {
      background: inherit !important;
    }
  }
}

// green if completed
// red if delayed and not submitted
// orange if submitted after delay
.mr-1 {
  margin-right: 5px;
}

.ant-progress-status-success .ant-progress-text {
  color: #1dd790 !important;
}

.task-category {
  border-radius: 16px 16px 16px 16px;
  padding: 0 10px;
}

.green-dot {
  height: 12px;
  width: 12px;
  background-color: #1dd790;
  border: 1px solid #1dd790;
  border-radius: 50%;
  display: inline-block;
}

.white-dot {
  height: 12px;
  width: 12px;
  border: 1px solid black;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
}

.red-dot {
  height: 12px;
  width: 12px;
  border: 1px solid red;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}


@keyframes donut-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.donut {
  display: inline-block;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: donut-spin 1.2s linear infinite;

  &.centered {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
